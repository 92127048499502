<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          Liste des utilisateurs connectés
        </b-card-title>
      </div>
    </b-card-header>
    <b-card-body>
      <b-table
        responsive="sm"
        :items="users"
        :fields="userTableColumns"
      >
        <template #cell(last_activity)="data">
          <span>{{ moment(data.value).format("LLL") }}</span>
        </template>
      </b-table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BTable,
} from 'bootstrap-vue'

export default {
  name: 'ConnectedUsersList',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BTable,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userTableColumns: [
        {
          key: 'last_activity',
          label: 'Dernière activité',
        },
        {
          key: 'name',
          label: 'Nom',
        },
        {
          key: 'email',
          label: 'Email',
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
