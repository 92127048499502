<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Utilisateurs connectés
        </b-card-title>
        <b-card-sub-title>Nombre d'utilisateurs connectés en simultané</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center justify-content-start">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <!--        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range' , dateFormat: 'H:i:ss',}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />-->
        <v-select
          v-model="perTime"
          :dir="'ltr'"
          :options="perTimeOptions"
          :clearable="false"
          class="select-time d-inline-block mx-50"
          :reduce="val => val.value"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        type="area"
        height="400"
        :options="lineChartSimple.chartOptions"
        :series="lineChartSimple.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import { $themeColors } from '@themeConfig'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vSelect from 'vue-select'

export default {
  name: 'ChartsOnlineUsersHistory',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    VueApexCharts,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      loading: false,
      intervalId: null,
      perTime: '30 minutes',
      perTimeOptions: [
        { label: '10min', value: '10 minutes' },
        { label: '30min', value: '30 minutes' },
        { label: '1h', value: '1 hours' },
        { label: '2h', value: '2 hours' },
        { label: '5h', value: '5 hours' },
      ],
      rangePicker: ['2019-05-01', '2019-05-10'],
      lineChartSimple: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: [$themeColors.warning],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span>'}${
                data.series[data.seriesIndex][data.dataPointIndex]
              }</span></div>`
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    }
  },
  watch: {
    perTime: {
      handler() {
        this.fetchOnlineUsersHistory()
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  mounted() {
    clearInterval(this.intervalId)
    this.fetchOnlineUsersHistory()
    this.pollingStats()
  },
  methods: {
    async fetchOnlineUsersHistory() {
      try {
        this.loading = true
        const timeOption = this.perTime.split(' ')
        const startTime = this.moment().subtract(timeOption[0], timeOption[1]).format('Y-MM-DD HH:mm:ss')
        const endTime = this.moment().format('Y-MM-DD HH:mm:ss')
        const { data } = await axiosIns.get('statsOnlineUsersHistory', {
          params: {
            start_time: startTime,
            end_time: endTime,
          },
        })
        const newCategories = []
        const newSeries = []
        data.online_users_history.forEach(el => {
          newCategories.push(this.moment(el.date).format('Y-MM-DD HH:mm'))
          newSeries.push(el.value)
        })
        this.lineChartSimple.series[0].data = newSeries
        this.lineChartSimple.chartOptions = {
          ...this.lineChartSimple.chartOptions,
          ...{
            xaxis: {
              categories: newCategories,
            },
          },
        }
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    pollingStats() {
      this.intervalId = setInterval(() => {
        this.fetchOnlineUsersHistory()
      }, 30000)
    },
  },
}
</script>

<style lang="scss">
.select-time {
  width: 110px!important;
}
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>
