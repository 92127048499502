<template>
  <div>
    <b-row>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="success"
          icon="LayoutIcon"
          :statistic="readableNum(stats.platform_count)"
          statistic-title="Plateformes"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="warning"
          icon="ZapIcon"
          :statistic="readableNum(stats.forecasts_count)"
          statistic-title="Pronostics"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="success"
          icon="UsersIcon"
          :statistic="readableNum(stats.users_count) + '/' + stats.platform_slot_sum"
          statistic-title="Joueurs"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="info"
          icon="MessageSquareIcon"
          :statistic="readableNum(stats.messages_count)"
          statistic-title="Messages"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="danger"
          icon="TrendingUpIcon"
          :statistic="stats.participation_rate + '%'"
          statistic-title="Participation"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="primary"
          icon="DribbbleIcon"
          :statistic="stats.competitions_count"
          statistic-title="Competitions"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="success"
          icon="RadioIcon"
          :statistic="readableNum(stats.online_users_count)"
          statistic-title="Joueurs en ligne"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="success"
          icon="BarChartIcon"
          :statistic="readableNum(stats.online_users_count_record)"
          statistic-title="record de joueurs en ligne"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="secondary"
          icon="UserPlusIcon"
          :statistic="readableNum(stats.prospects_count)"
          statistic-title="Prospects"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-vertical
          v-else
          color="warning"
          icon="DollarSignIcon"
          :statistic="`${readableNum(stats.platform_slot_sum * 2)}€`"
          statistic-title="Euros"
        />
      </b-col>
    </b-row>
    <!--    <b-row>
      <b-col
        xl="4"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-with-line-chart
          v-else
          icon="UserCheckIcon"
          color="success"
          statistic="659.8k"
          statistic-title="Active Users"
          :chart-data="activeUsers.series"
        />
      </b-col>
      <b-col>
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-with-line-chart
          v-else
          icon="MessageSquareIcon"
          color="info"
          statistic="659.8k"
          statistic-title="Messages"
          :chart-data="revenueGenerated.series"
        />
      </b-col>
    </b-row>-->
    <b-row>
      <b-col>
        <ChartsOnlineUsersHistory />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ConnectedUsersList :users="connectedUsers" />
      </b-col>
    </b-row>
    <!--    <b-row>
      <b-col>
        <ChartsHomeUsers />
      </b-col>
    </b-row>-->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ChartsOnlineUsersHistory from '@/components/charts/home/ChartsOnlineUsersHistory'
import ChartsHomeUsers from '@/components/charts/home/ChartsHomeUsers'
import ConnectedUsersList from '@/components/lists/home/ConnectedUsersList'
import axiosIns from '@/libs/axios'
import { readableNum } from '@/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSpinner,
    StatisticCardWithLineChart,
    StatisticCardVertical,
    ChartsOnlineUsersHistory,
    ChartsHomeUsers,
    ConnectedUsersList,
  },
  data() {
    return {
      readableNum,
      activeUsers: {
        series: [
          {
            name: 'Active Users',
            data: [750, 1000, 900, 1250, 1000, 1200, 1100],
          },
        ],
      },
      revenueGenerated: {
        series: [
          {
            name: 'Revenue',
            data: [350, 275, 400, 300, 350, 300, 450],
          },
        ],
        analyticsData: {
          revenue: 97500,
        },
      },
      stats: {},
      connectedUsers: [],
      loading: true,
      intervalId: null,
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  mounted() {
    this.fetchStats()
  },
  methods: {
    async fetchStats() {
      try {
        this.loading = true
        const { data } = await axiosIns.get('stats')
        this.stats = data
        this.connectedUsers = Object.values(data.online_users)
        this.loading = false
        this.pollingStats()
      } catch (err) {
        this.loading = false
        console.log(err)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    pollingStats() {
      this.intervalId = setInterval(() => {
        try {
          axiosIns.get('stats').then(({ data }) => {
            this.stats = data
            this.connectedUsers = Object.values(data.online_users)
          })
        } catch (err) {
          if (err.response) { console.log(err.response.statusText) }
        }
      }, 30000)
    },
  },
}
</script>

<style>

</style>
